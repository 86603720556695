import { Link } from "react-router-dom";
import "./levels.css";

const LevelsText = () => {
  return (
    <div>
      <Link to="/" className="backButton">
        Back
      </Link>
      <div className="levelsDiv">
        <h1> vibration levels</h1>
        <p>where are you now? and where are you going?</p>
        <div className="typesOfConsciousness">
          <Link to="/levels/enlightenment">
            <p className="level">Enlightenment 1000</p>
          </Link>

          <Link to="/levels/peace">
            <p className="level peace">Peace, Bliss, Illumination 600-699</p>
          </Link>

          <Link to="/levels/unconditionallove">
            <p className="level unconditionalLove">
              Unconditional Love, Joy, Ecstacy 540-599
            </p>
          </Link>
          <Link to="/levels/reason">
            <p className="level reason">Reason 400</p>
          </Link>
          <Link to="/levels/acceptance">
            <p className="level acceptance">Acceptance 350</p>
          </Link>
          <Link to="/levels/willingness">
            <p className="level willingness"> Willingness 310</p>{" "}
          </Link>
          <Link to="/levels/neutrality">
            <p className="level neutrality">Neutrality 250</p>
          </Link>
          <Link to="/levels/courage">
            <p className="level courage">Courage 200</p>
          </Link>
          <Link to="/levels/pride">
            <p className="level pride">Pride 175</p>
          </Link>
          <Link to="/levels/anger">
            <p className="level anger">Anger 150</p>
          </Link>
          <Link to="/levels/desire">
            <p className="level desire">Desire 125</p>
          </Link>
          <Link to="/levels/fear">
            <p className="level fear">Fear 100</p>
          </Link>
          <Link to="/levels/grief">
            <p className="level grief">Grief 75</p>
          </Link>
          <Link to="/levels/apathy">
            <p className="level apathy">Apathy, hatred 50</p>
          </Link>
          <Link to="/levels/guilt">
            <p className="level guilt">Guilt 30</p>
          </Link>
          <Link to="/levels/shame">
            <p className="level shame">Shame 20</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default LevelsText;

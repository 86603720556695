import { Link } from "react-router-dom";

const Pride = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Pride</h1>
        <p>
          this level is characterised by a rise in self-esteem which balms all
          the pain experienced at lower levels of consciousness. but it feels
          good only in contrast to the lower levels.
        </p>
        <p>
          the downside of pride is arrogance and denial, which blocks growth.
          the recovery from addictions is impossible because emotional problems
          are denied.
        </p>
        <p>
          pride, like anger and fear, is still a defensive posture because of
          its intrinsic vulnerability that requires its positions be guarded and
          defended. pride is gratifying yet a block to miving on to the solid
          ground of courage, which is beyond fear because of its vulnerability.
        </p>
        <p>
          the self-esteem of pride rests on an inflated and exaggerated opiinon
          rather than on reality. thus, the ego searches for confirmation, which
          rests on the insecure premises of opinion.
        </p>
        <p>
          pride is operationally serviceable as a transitory self-reward for
          successful accomplishment, but the error occurs when the ego assumes
          that it is the 'me' that is being rewarded rather than the behaviour
          itself. this leads to the seeking of the reward of admiration by which
          actions become subservient to the goal of winning approval.
        </p>
        <p>
          the motivating pattern persists in most adults to varying degrees, but
          with progressive maturity, the pattern becomes internalised and
          self-reward occurs by virtue of the authority of internalised parental
          figures and standards.
        </p>
        <p>
          pride is often dependent on social image and its expressions via
          posessions, publicity, title, wealth. social status and its symbols
          motivate subcultures, which have thir own intrinsic earnmarks of
          success.
        </p>
        <p>
          each subculture has its own ranking system and stratifications. these
          appear in nuances of roles and priviliges, as well as responsibilities
          and expectations, with consequent rewards and obligations as a result
          of complex system motivators. values can automatically accure to
          certain activities and qualities, such as education, personality
          traits, and styles of behaviour and speech.
        </p>
        <p>
          the social pressure of subcultures is quite strong and often
          determines the content of internalised behavioural patterns that
          define success or failure and affect pride, self-esteem, and perceived
          social value.
        </p>
        <p>
          the same behavioural style that leads to approval or success in one
          subculture may spell failure and rejection in another. "When in Rome,
          do as the Romans do."
        </p>
      </div>
    </div>
  );
};
export default Pride;

import { Link } from "react-router-dom";

const Anger = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Anger</h1>
        <p>
          anger can be a fulcrum by which the oppressed are eventually
          catapulted to freedom. anger over social injustice, victimisation and
          inequality has energised great movements that have led to major
          changes in the structure of society.
        </p>
        <p>
          anger, however, expresses itself most pften as resentment or as a
          lifestyle that is exemplified by irritable explosive people who are
          oversensitive to slights and become 'injsutice collectors'.
        </p>
        <p>
          frustration results from exaggerating the importance of desires. anger
          easily leads to hatred, which has an erosive effect on all areas of a
          person's life.
        </p>
        <p>
          anger as an emotion is prevalent throught society as a transient
          reaction, but anger as a level of consciousness is indicative of
          dominance by a pervasive, negative energy field that is reflective of
          the ego's distorted perceptions.
        </p>
        <p>
          a primary aspect of the distortion is a narcissistically-oriented
          worldview and expectations that the world should cater and conform to
          one's wishes and perceptions. the result is chronic frustration and
          resentment.
        </p>
        <p>
          the narcissistic ego is competitive and prone to feel flighted and
          insulted with even minimal provocation, as the core of the ego sees
          itself as sovereignity that expects priority and agreement or
          compliance with its expectations, as well as satisfaction of its wants
          and proclivities.
        </p>
        <p>
          a major defence of the ego is to project its superego onto the outer
          world and then live in fear of it in the form of fears of vengeance.
          thus, the anrgy ego fears truth, honesty, and balance, which would
          reduce its dominance.
        </p>
        <p>
          the anrgy person's ego sees relationships as a battleground for
          dominance, control, and primitive attitudes and actions. the ego at
          this level extracts pleasure form negativity.
        </p>
        <p>
          to people who feel inwardly weak and vulnerable, anger seems like
          strength, whereas to strong people, anger is seen as a primitive,
          vulgar weakness that is disliked and viewed as immature and
          embarassing.
        </p>
        <p>
          in and of itself anger is merely a subjective emotion that does not
          actually of itself accomplish anything in the world as would the use
          of reason and restraint.
        </p>
        <p>anger is used by ego as a substitute for courage.</p>
      </div>
    </div>
  );
};
export default Anger;

import { Link } from "react-router-dom";

const Neutrality = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Neutrality</h1>
        <p>
          to be neutral means to be relatively unattached to outcomes. not
          getting one's way is no longer experienced as defeating, frightening,
          or frustrating.
        </p>
        <p>
          at the neutral level a person can say: 'well, if i don't get this job,
          then i'll get another'. this is the beginning of inner confidence.
        </p>
        <p>
          people at neutrality have a sence of well-being. this is the level of
          safety. people at this level are easy to get along with and safe to
          associate with because they are not interested in conflict,
          competition or guilt. they are comfortable and basically emotionally
          undisturbed. this attitude is nonjudgmental and does not lead to any
          need to control other people's behaviours.
        </p>
        <p>
          this level results in greater freedom for self and others. one is free
          from trying to 'prove' anything about oneself.
        </p>
        <p>
          the level of neutrality is relatively free of anixiety for it doesn't
          place survival value on preconceived outcomes. thus, the source of
          happiness is not projected externally onto others or the outside
          world.
        </p>
        <p>
          nonattachment vs detachment. detachment indicates withdrawal as well
          as negation, leading to indifference, which in itself is a defense
          against the fear of attachment. the pathway to the state of
          Enlightement is via nonattachment rather than negation. nonattachment
          means nondependence on form. it allows for the freedom from the
          attraction of projected values and anticipations such as gain.
        </p>
        <p>
          without fear of either attraction or aversion, neutrality allows for
          participation and the enjoyment of life because life becomes more like
          a play than a high-stakes involvement. this is consistent with the
          teachings of Tao, in that the flow of life is neither sought nor
          resisted. thus, life becomes effortless and existence itself is
          pleasurable, without conditions, and easygoing like a cork in the sea.
        </p>
      </div>
    </div>
  );
};
export default Neutrality;

import { Link } from "react-router-dom";

const UnconditionalLove = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Unconditional Love, Joy, Ecstasy</h1>
        <p>
          as love becomes increasingly unconditional, it begins to be
          experienced as inner Joy. this is not the sudden joy of a pleasurable
          turn of events but instead a constant accompaniment to all activities.
        </p>
        <p>
          Joy arises from within each moment of existence rather than from any
          outer source. it is also the level of healing.
        </p>
        <p>
          characteristic of this energy field is a capacity for enormous
          patience and the pesistence of a positive attitude in the face of a
          prolonged adversity. the hallmark of this state is compassion. people
          who have attained this level have a notable effect on others.
        </p>
        <p>
          the world one sees is illuminated by the exquisite beauty and
          perfection of creation. everything happens effortlessly by
          synchronicity and one sees the world and everything in it to be an
          expression of Love and Divinity.
        </p>
        <p>
          individual will merges into Divine will. one feels the power of the
          presence that fascilitates phenomena outside conventional expectations
          of reality, termed 'miraculous' by the ordinary observer. these
          phenomena represent the power of the energy field, not of the
          individual. in other worlds, the energy field is the soul, a part of
          the Divine, that makes everything happen, not the particular
          individual in a human physical form. the soul is all-powerful because
          it is a part of God.
        </p>
        <p>
          the emergence of progressively higher levels of consciousness requires
          periods of adjustment, such as that required by a new pair of glasses,
          and this, worldly function may be impaired periodically due to shifts
          of orientation. in this reorientation, phenomena are discovered to be
          happening spontaneously of their own rather than via the usual
          presumed premise and perception of cause and effect.
        </p>
        <p>
          it is also progressively discovered that there is no 'doer' of
          actions, and one witnesses the autonomous unfoldment of karmic
          potentiality from a new paradigm of reality that is beyond the
          presumptive dualistic principle of causation. thus, life becomes an
          endless series of revelations of intrinsic charm and delight that
          initially seem amazing. then comes the realisatoin that what appears
          to be miraculous is merely the constant unfolding of the potentiality
          of the evolution of Creation by which the subjective experience of
          time dissolves.
        </p>
        <p>
          likewise, the perception of 'change' is replaced by the progressive
          emergence of the ongoingness of Creation as becoming the fulfillment
          of potentiality's actualising into manifestation.
        </p>
        <p>
          with neither past nor future, likewise, there is no 'now', and it is
          comprehended that the past, present, or future are all illusory
          contextualisations consequent to the limitation of a paradigm.
        </p>
        <p>
          the shift forward merely requires the surrendering of any and all
          belief systems and the understanding that all fear is illusion.
          nothing is actually the way the ego has perceived it for the linear
          dimension is merely presumptive, and nonlinear absolute is a very
          different paradigm that operates on totally different principles that
          are self-revealing rather than sequential understandings or
          comprehension.
        </p>
      </div>
    </div>
  );
};
export default UnconditionalLove;

import { Link } from "react-router-dom";

const Peace = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Peace, Bliss and Illumination</h1>
        <p>
          this energy field is associated with the experience of
          god-consciousness. the distinction between the subject and object
          dissapears, and there is no specific focal point of perception
        </p>
        <p>
          action at the level of 600 and above is perceived as occurring in slow
          motion, suspended in time and space. all is alive, radiant and
          continuously flowing, unfolding in an exquisitely coordinated
          avolutionary dance in which sugnificance and source are overwhelming.
          this revelation takes place without thought or conception so that
          there is an infinite silence in the mind, which has stopped
          conceptualising.
        </p>
        <p>
          that which is witnessing and that which is witnessed are the same
          identity. the observer dissolves and becomes the observation.
        </p>
        <p>
          great works of art, music, and architecture that calibrate between 600
          and 700 can transport us temporarily to higher levels of consciousness
          and are universally recognised as inspirational and timeless.
        </p>
        <p>
          in the stillness, all occurs of its own accord, autonomously and
          spontaneously. sound has no effect on the silence that persists even
          within the sound.
        </p>
        <p>
          whether the body continues on and survives or not is uninteresting and
          actially without meaning. it is a matter of no interest and up to the
          Universe to direct. if the karmic propensities are aligned with
          physical continuation, the body survives. if not, the body is simply
          abandoned, for it came from the earth and return to the earth when it
          has served the purpose of the spirit.
        </p>
        <p>
          creation is witnessed as the unfolding and revelation of the emergence
          of infinite potentiality as creation. thus, there is no duality of a
          'this'(creator) creating a 'that' (creation) for creator and creation
          are one and the same.
        </p>
        <p>
          everything that exists is perfect and complete. creation doesn't move
          from imperfection to perfection, as is witnessed by the egp, but
          instead moves from perfection to perfection. the illusion of moving
          from imperfection to perfection is a mentalisation.
        </p>
        <p>
          a rosebud is not an imperfect rose but is a perfect rosebud. when half
          open, it is a perfect unfolding flower, and when comlpetely opened, it
          is a perfect open flower. as it fades, it is a perfect faded flower
          and then becomes a perfect withered plant, which then becomes
          perfectly dormant. each is therefore perfect at each expansion of its
          expression as the emergence and unfoldment of the evolution of
          creation.
        </p>
        <p>
          the illusion of 'change' is replaced by the witnessing of the process
          of the manifestation of actuality from potentiality.
        </p>
        <p>
          equal to everything else, the body is also autonomous and moves about
          on its own. in the level of the 600s, there is no volitional causal
          locus, such as a personal self, a 'me', or an 'I' that is imagined to
          be a causal agent or a 'decider' of action.
        </p>
      </div>
    </div>
  );
};
export default Peace;

import { Link } from "react-router-dom";

const Courage = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Courage</h1>
        <p>
          level of empowerment. zone of exploration, accomplishment, fortitude,
          determination.
        </p>
        <p>life is seen as exciting, challenging, and stimulating.</p>
        <p>
          courage implies the willingness to try new things and to deal with the
          vicissitudes of life. one is able to cope with and effectively handle
          the opportunities of life. there is the capacity to face fears and to
          grow despite any perceived defects. obstacles that defeat people whose
          consciousness level is below 200 act as stimulants to those who have
          evolved into courage.
        </p>
        <p>
          crossing over level 200 is the most critical step in the evolution of
          human consciousness. there is intuitive acceptance of the truth of
          accountability as a spiritual and social reality. truth is now seen as
          an ally instead of an enemy. alignment with truth rather than gain
          brings strength, self-respect, and true empowerment rather than ego
          inflation.
        </p>
        <p>"What gains a man to win the world but lose his soul?"</p>
        <p>
          courage brings inner confidence and a greater sense of personal power
          because it is not dependent on external factors or results. to choose
          integrity and self-honesty is self-rewarding and reinforcing.
        </p>
        <p>
          there is a greater sense of inner freeedom due to the relief from
          guilt and fear. steadfastness and integrous performance result in
          inner gratifications that accrue from the satisfactory fulfillment of
          inner standards.
        </p>
        <p>
          at this level, it is the effort and intention, not just the result,
          that are important.
        </p>
        <p>
          the long-term goal of life becomes the development of inner
          potentials, such as strength, rather than the acquisitions of
          externals.
        </p>
        <p>
          although courage is both recognised and rewarded by society, the
          social approval becomes only secondary. temptations to violate
          integrity for gain are recognised and rejected.
        </p>
        <p>
          from consciousness calibration research,, it is verified that everyone
          knows unconscously when they are being lied to, which is revealed by
          the muscle test that instantly discerns truth from falsehood. this
          weakening of the body's musculature response and the alterations of
          the energy flow through the acupuncture system indicate that the
          awareness of the difference between truth and falsehood is intrinsic
          to life energy and it reflected in the body's response, even if the
          mind denies it.
        </p>
        <p>
          self-honesty brings relief from the negative emotions of lower energy
          fields. negative emotions are now unwelcome and unpleasant in both
          oneself and others. argument, conflict, and discord are no longer
          attractive because they have lost their ego inflation.
        </p>
        <p>
          transitory difficulties of social realities are acepted aspects of
          human life rather than being seen as personal insults. there is a
          progressive distaste for violence and the theatrics of political and
          ideollogical extremism, as the comfort of inner-calm is preferred to
          the excitement of adrenaline.
        </p>
        <p>
          with maturity, there is the development of a sense of humor that
          replaces hostile attacks and outbursts. the peace and quite that seem
          boring to lower levels of consciousness are now preferred as are
          periods of calm during which to think and contemplate.
        </p>
        <p>
          reflection now becomes more important than emotionalised reactivity.
        </p>
        <p>
          desires are less demanding. and the quality of patience replaces
          drivenness and intolerance for delay of gratification.
        </p>
        <p>
          personal happiness becomes an achievable goal, and gratitude replaces
          resentment, self-pity, and blaming others.
        </p>
        <p>
          the subjective experience of the world changes for the better, and
          people seem more friendly and hospitable.
        </p>
        <p>
          courage leads to exploration and self-development, and it facilitates
          personal growth and the evolution of consciousness.
        </p>
        <p>
          the critical key to moving into the strength of courage is the
          acceptance of personal responsibility and accountability. this major
          move requires personal relinguishment of a victim/perpertator
          dualistic fallacy that socially undermines integrity via blame by
          which an external 'cause' or social condition replaces intergous
          personal autonomy and self-honesty.
        </p>
        <p>
          courage doesn't mean absence of fear but the willingness to surmount
          it, which, when accomplished, reveals hidden strength and the capacity
          for fortitude.
        </p>
      </div>
    </div>
  );
};
export default Courage;

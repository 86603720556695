import { Link } from "react-router-dom";

const Fear = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Fear</h1>
        <p>
          fear of danger runs much of the world, spurring on endless activity.
          fear of enemies, old age or death is a basic motivator in the lives of
          most people.
        </p>
        <p>
          fear makes one numb, limits the ability to think and act rationally.
        </p>
        <p>
          the world appears hazardous, filled with traps. fear is the favoured
          official tool for control by oppressive agencies. the proliferation of
          fears is as limitless as the human imagination.
        </p>
        <p>
          fearful thinking can baloon into paranoia or generate neurotic
          defensive structures, becoming a contageous social trend.
        </p>
        <p>fear limits growth of the personality and leads to inhibition.</p>
        <p>
          the fearful seek strong leaders who appear to have conquered their
          fear to lead them out of its slavery.
        </p>
        <p>
          fear as caution subserves survival in contrast to irrational fears,
          which, as a prevailising mode of behaviour, become uncomfortable and
          decrease the level of consciousness.
        </p>
        <p>
          to let go of fear, one can start looking at the universe as loving
          instead of evil.
        </p>
        <p>
          another way of conquering fear is to let it be, and feel it in the
          body. ask yourself, where does it feel and how does it feel?
        </p>
      </div>
    </div>
  );
};
export default Fear;

import { Link } from "react-router-dom";
import "./frequencyUpgrade.css";

const FrequencyUpgrade = () => {
  return (
    <>
      <Link to="/" className="backButton">
        Back
      </Link>
      <div className="upgradeContainer">
        <h1>Upgrade your frequency</h1>
        <p>
          each person experiences, perceives and interprets the world and its
          events in accordance with their own predominant level of
          consciousness.
        </p>
        <p>
          a transofrmation (a quantum jump) happens when you fully go up a
          level.
        </p>
        <p>generally speaking, the higher you go, the easier life becomes.</p>
        <p>
          the key to lifting up your vibration is to experience your emotions,
          position your attention within your physical body and focus on the
          present moment. allow what is to be. to awaken to a realisation that
          the world that you see is your own reflection.
        </p>
        <p>
          I've got a list of ways that help me to lift my vibration. Though the
          proper move from one vibration to the next one is a work that takes
          time, soemtimes a day and sometimes years, this list of ways to rise
          your virbration consists of stepping stone towards your desired
          destination. remember, it is all about lifting your inner state up.
          because when you feel balanced, when you enjoy every moment for what
          it is, life gets better:
        </p>
        <ul>
          <li> turn on your favourite music and move your body</li>
          <li>
            lie on the bed and feel how you are relaxing every cell of your body
          </li>
          <li>feel where it hurts and breathe out the heaviness</li>
          <li>
            other breathing techniques, like lying on the bed, breathing in for
            4seconds and breathing out for 8seconds.
          </li>
          <li>
            active physical exercise to shake out the stagant feelings and
            emotions.
          </li>
          <li>massage</li>
          <li>change of the scenery, new location, travel, movement</li>
          <li>
            feeling heard and appreciated during a soulful conversation with a
            close friend
          </li>
          <li>lying on the grass, looking at the sky, talking to nature</li>
          <li>
            recognising that the universe loves you and you are literally made
            out of love and everything that surrounds you is love
          </li>
          <li>
            expressing yourself in an artistic form, like writing or painting,
            not caring an inch about the quality of the result of this artistic
            process, but fully focusing on the process itself
          </li>
          <li>talking to God</li>
          <li>
            watching a good movie or reading a good book that resonates with
            your current circumstance
          </li>
          <li>going outside meeting new people</li>
        </ul>

        <p>
          at the lower consciousness levels, the ego dominates life based
          primarily on the techniques and emotions of animal survival, which are
          aligned with pelasure, predation, and gain. as consciousness evolved
          over time, some species rose to consciousness level 200, which is the
          major demarcation that heralds reliance on power rather than force.
        </p>
        <p>
          the world is experienced and viewed quite differently at consciousness
          level 275 in contrast to level 155. these levels profoundly influence
          all aspects of life. the degree of difference is almost equivalent to
          describing two different, contrasting civilizations with different
          levels of the quality of interpersonal relationships, pleasure,
          happiness in life, worldly success, philosophies, politics, and most
          importantly, the level of spiritual awareness and alignment.
        </p>
        <p>
          the learning and adaptation of constructive and more mature attitudes
          result in a more pleasant personality that is aligned with greater
          overall satisfaction with self and others.
        </p>
        <p>
          certain qualities that are valued and supported by all successfull
          societies throught time - calm, dependable, diplomatic, ehical, fair,
          flexible, friendly, genuine, healthy - all calibrate over 200.
        </p>
        <p>
          The road to spiritual awareness is supported by the fact that higher
          motivations are reinforced by energies that reflect power, whereas
          egoistic positions are weak, limiting, and exhausting.
        </p>
      </div>
    </>
  );
};

export default FrequencyUpgrade;

import { Link } from "react-router-dom";

const Shame = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Shame: despair</h1>
        <p>feels like: wanting to be invisible</p>
        <p>personality: shy, withdrawn, introverted, self-depreciating</p>
        <p>used in society: as a fear of dissaproval, tool for cruelty</p>
        <p>factors leading to: neglect, physical, emotional, or sexual abuse</p>
        <p>
          prone to: bizzare crimes, paranoia, psychosis, hallucinations of an
          accusatory nature
        </p>
        <p>
          to go up: see that all fear is illusion, so it is safe to let go of
          the ego attachments. ego is not the source of life, no matter how
          intensely the experience may seem. with the surrender of what seems to
          be the very source, irreducible core, and essence of one's life, the
          personal 'I', along with the mind, dissolves into the Infinite 'I' of
          the Eternal, with its profound peace and state of Oneness beyond all
          time.
        </p>

        <p>
          despair is characterised by helplessness and hopelessness, it is a
          hellish state to endure. the will to live is lost, while the act of
          suicide is not possible due to lack of energy. passive suiside occurs
          through the failure to eat or provide for physical necessities.
        </p>
        <p>
          shame is reflective of self-hatred, which can result in homicidal
          aggression.
        </p>
        <p>
          depression is accompanied by major changed in brain physiology and low
          levels of critical neurotransmitters, such as norepinephrine and
          serotonin.
        </p>
        <p>
          this state may also be a transitory phase as a consequence of intense
          inner spiritual work. severe spiritual depression, 'the dark night of
          the soul', can represent the last toehold of the ego as it fights for
          survival. the ego's basic illusion is that it is God and that without
          it, death will occur. this, wht is described as 'the dark night of the
          soul' is actually the dark night for the ego. it is not really the
          soul but the ego that is in the dark.
        </p>
        <p>
          some comfort can be obtained by recalling the spiritual dictum that
          one can only go as high as they have been low, or that Jesus Christ
          sweat blood in Gethsemane, or that the Buddga reported that he felt as
          though his bones were being broken and he was being attacked by
          demons.
        </p>
      </div>
    </div>
  );
};
export default Shame;

import instaLink from "../public/imgs/instagram.jpg";
import { Link } from "react-router-dom";

const Socials = () => {
  return (
    <>
      <Link to="/">Back</Link>
      <p> hey, nice to meet you</p>
      <img className="instaLink" src={instaLink} />
    </>
  );
};

export default Socials;

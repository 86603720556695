import React, { useImperativeHandle, useState, forwardRef } from "react";
import { useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const Lasers = forwardRef(({ onLaserHit, onLaserMove }, ref) => {
  const [lasers, setLasers] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      setLasers((prevLasers) =>
        prevLasers.map((laser) => ({
          ...laser,
          position: laser.position.clone().add(laser.direction),
        }))
      );
    }, 1000 / 60);
    return () => clearInterval(interval);
  }, []);

  const shootLaser = (position) => {
    setLasers((prevLasers) => [
      ...prevLasers,
      {
        position: position.clone(),
        direction: new THREE.Vector3(0, 0, 1).normalize(),
        id: Math.random(),
      },
    ]);
  };

  useImperativeHandle(ref, () => ({
    shootLaser,
  }));

  useFrame(() => {
    setLasers((prevLasers) =>
      prevLasers.filter((laser) => laser.position.length() < 1000)
    );

    if (onLaserMove) {
      onLaserMove(lasers);
    }
  });

  return (
    <>
      {lasers.map((laser) => (
        <mesh
          key={laser.id}
          position={laser.position}
          onClick={() => onLaserHit(laser.id)}
        >
          <boxGeometry args={[0.01, 0.1, 2]} />
          <meshBasicMaterial color="red" />
        </mesh>
      ))}
    </>
  );
});

export default Lasers;

import { Link } from "react-router-dom";

const Apathy = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Apathy, hatred</h1>

        <p>
          characterised by: neglect, indifference, poverty, despair,
          hopelessness.
        </p>
        <p>
          feels like: lacl of energy to take advantage of what may be available,
          the future looks bleak, no resources.
        </p>
        <p>
          society often lacks sufficient motivation to be of any real help to
          cultures or individuals at this level and sees them as drains of
          resources.
        </p>
        <p>
          sloth is included as one of the seven deadly sins because it is a
          rejection of God's gift of life. in this state, there is no concern
          for the welfare of others or even appropriate concern for the quality
          of one's own life. the same attitude is then projected onto God who is
          seen as rejectingm unavailable and uncaring.
        </p>
        <p>
          the feeling of worthlessness reinforces negative social attitudes and
          behaviours that result in poverty and low quality of life.
        </p>
        <p>
          responsibility is rejected and replaced by a chronic victim mentality
          that seeks to avoid the real issues by projecting the supposed source
          to the external world, which is then comfortably blamed as being the
          'cause'.
        </p>
        <p>
          apatheic periods may occur temporarily in almost anyone's life, while
          such a lifestyle becomes a denial of the value of life.
        </p>
        <p>
          {" "}
          in what is seen as apathy, there is a strong internal resistance as a
          subtly disguised pride and egotism described as 'I can't'. the
          persistence of the ego is so strong that it frequently takes a mass
          catastrophe, such as war or an earthquake, to confront it to the
          degree that is willing to surrender.
        </p>
      </div>
    </div>
  );
};
export default Apathy;

import { Link } from "react-router-dom";
import { useState } from "react";
import { Canvas } from "@react-three/fiber";
import Spaceship from "./objects/Spaceship";
import Ruby from "./objects/Ruby";
import Light from "../designElements/Light";
import DistortBall from "../designElements/DistortBall.";
import * as THREE from "three";

import "./SpaceGame.css";
import Score from "./components/Score";
import DynamicSky from "./components/DynamicSky";
import Asteroids from "./components/Asteroids";
import Laser from "./components/Laser";

const GameHolder = () => {
  const [spaceshipPosition, setSpaceshipPosition] = useState(
    new THREE.Vector3()
  );
  const [lasers, setLasers] = useState([]);

  const handleShootLaser = (laser) => {
    setLasers((prevLasers) => [...prevLasers, laser]);
  };

  return (
    <div className="noScroll thingsFromSpace">
      <Link to="/" className="backButton">
        Back
      </Link>
      <Score />

      <Canvas camera={{ fov: 45, near: 0.1, far: 2000, position: [0, 5, -10] }}>
        <Spaceship
          onPositionChange={setSpaceshipPosition}
          onShoot={handleShootLaser}
        />
        <Ruby />
        <DistortBall
          hoverColor="#E8E6CA"
          position={[-2, 10, 5]}
          distort={0.5}
          speed={0.9}
        />
        <DistortBall
          hoverColor="#00F5D4"
          position={[2, 10, 5]}
          distort={0.8}
          speed={1.3}
        />
        <DistortBall
          hoverColor="#00BBF9"
          position={[6, 10, 5]}
          distort={1.1}
          speed={1.3}
        />
        <DynamicSky spaceshipPosition={spaceshipPosition} />
        <Laser laser={lasers} setLaser={setLasers} />
        <Light />
        <Asteroids
          spaceshipPosition={spaceshipPosition}
          lasers={lasers}
          setLasers={setLasers}
        />
      </Canvas>
    </div>
  );
};

export default GameHolder;

import { Link } from "react-router-dom";

const Guilt = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Guilt and Vindictive Hate</h1>
        <p>
          guilt is the consequence of the memory of regretted past actions as
          they are recalled. these can be transcended by recontextualisation -
          mistakes are the natural, impersonal consequence of learning and
          development and therefore unavoidable.
        </p>
        <p>
          used: as blame to manipulate and punish, for coercion and control.
        </p>
        <p>
          manifests itself: as remorse, penance, self-hatred, self-punishment,
          suicide, self-abnegation, self-propagating addiction,
          self-recrimination, masochism, victimhood, undeserving of salvation.
        </p>
        <p>guilt is a learned behaviour with major psychological components.</p>
        <p>
          a spiritual paradix is represented by the religious teaching of
          'hating sin', which merely expresses the very thing it deplores.
        </p>
        <p>
          to bypass: feel compassion, forgiveness, and realise that individuals
          are limited, ignorant, and don't always understand what they do.
        </p>
        <p>
          guilt can be an educative emotion that arises as a warning not to
          repeat the same mistake. the past cannot be rewritten, but it can be
          recontextualised so as to be the source of constructive learning.
        </p>
        <p> the literal, absolute definition of the word 'sin' is 'error'.</p>
        <p>
          self-forgiveness is fascilitated by humility and acceptance of
          limitations.
        </p>
        <p>
          excessive guilt and remorse are a disguised form of egotism in which
          the self becomes blown up, exaggerated, and the hero of the tragedy,
          the negativity of which feeds the ego. release from guilt requires
          surrender of this egotism because the ego reenergises itself through
          the negativity.
        </p>
        <p>
          'i should have known better' is another egoist position, which brings
          in the hypothetical, which is always fallacious. wallowing in guilt is
          feeding the ego and is an indulgence.
        </p>
        <p>
          in psychological terms, the source of guilt is the superego, so named
          by Freud. this is the part of mind comprised of introjected judgments,
          points of view, and learned content.
        </p>
        <p>
          a hypertrophied superego can be the source of excessive guilt, when
          projectd onto others, justifies vindictiveness ine xtreme forms as
          revenge against the 'evil' enemy. this rationalised killing others as
          their being 'deserving' of death. the worst ravages of humanity have
          often been done in the name of God - the classic Luciferic inversion
          of good and evil.
        </p>
        <p>
          God is not a sadist, so self-degradation or self-punishment serves
          neither God nor one's fellowmen.
        </p>
        <p>
          reluctance to forgive is a consequence of the illuion that others do
          not 'deseve' it. in reality, it is the forgiver and not the forgiven
          who benefits the most.
        </p>
        <p>
          forgiveness could not be done by the ego/mind because it lacks the
          necessary power when it is caught up in the energy field of hate,
          which calibrates at only 30. the transformative source of power cannot
          originate from the mind or the personality called the personal 'I'.
          the necessary power resides in the nonlinear quality of consciousness
          termed the 'will'.
        </p>
        <p>
          guilt-ridden cultures commonly have a negative view of God as being
          judgmental, vindictive, angry and punitive via natural disasters,
          which are contextualised as punishments for wickedness.
        </p>
        <p>
          the public often expresses concern that the guilty might go
          unpunished. anyone familiar with the reality of consciousness realises
          that no such thing is possible. everyone is accountable ot the
          universe and is subject to Divine Justice by the very dynamics of the
          universe itself. the are no accidents in the universe.
        </p>
        <p>
          personal past history represents the best that one could have done
          then under the given circumstances, which included one's perceptions
          and emotional mental states at the time. every given moment includes
          limitation. what we were is not what we are now. mistakes are
          intrinsic to the learning process, which is the fate of the human
          condition itself.
        </p>
        <p>
          in the long run, the consequences of merely being kind to others and
          all of life have far greater positive consequences thatn worldly
          success, defined by the ego. it is beneficial to periodically reassess
          goals and ask whether they are really important or just the
          consequence of egotism.
        </p>
        <p>
          true success is simply the automatic consequences of being the best
          that one can be as a lifestyle, without looking for gain.
        </p>
      </div>
    </div>
  );
};
export default Guilt;

import { Link } from "react-router-dom";

const Love = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Love</h1>
        <p>
          characterised by the development of an energy field that is
          progressively unconditional, unchanging, and permanent. it does not
          fluctuate because its source is within the person who loves and is not
          dependent on external factors.
        </p>
        <p>
          lovingness is a way of being in and relating to the world and is
          forgiving, nurturing and supportive.
        </p>
        <p>
          love is not intellectual and does not proceed from the mind. love
          emanates from the heart. it has the capacity to lift others and
          accomplish great feats because of its purity of motive.
        </p>
        <p>
          as reason is bypassed, there arises the capacity for instantaneous
          recognition of the totaity of a problem and a major expansion of
          context. reason deals with particulars, whereas love deals with
          wholes.
        </p>
        <p>
          love takes no position and thus is global, rising above the separation
          of positionality. it is then possible to be 'one with another' as
          there are no longer any barriers.
        </p>
        <p>
          love dissolves negativity by recontextuualising it rather than by
          attcking it. as such, it is benign, supportive, and nurtures life. it
          is the level of true happiness.
        </p>
        <p>
          in terms of the evolution of consciousness, htis level reflects
          transcendence of identification with the limiting linear domain and
          its positionalities to the awareness of subjectivity as the primary
          state that underlines all experience.
        </p>
        <p>
          the energy field of life is innately gratifying in and of its own
          quality.
        </p>
        <p>
          love is available everywhere and although it may start as conditional,
          with time and intention, it becomes a way of life and a way of
          relating to life in all its expressions.
        </p>
        <p>
          as love progresses, it seeks no return or gain for it is
          self-rewarding by virtue of its completeness since it has no needs.
        </p>
        <p>
          the capacity for love grows, so that the more one loves, the more one
          can love, and there is no end point or limitation.
        </p>
        <p>
          to be loving is also to be lovable because the world is the
          reflection.
        </p>
        <p>
          at the lower levels of consciousness, what is perceived as love is
          conditional and identified with possession, passion, romance, and
          desire, which are projected onto people or objects to give them an
          exciting specialness and glamour that tend to fade after the prized
          object or relationship is obtained. infatuations tend to be frantic
          with a fear of loss that leads to despair.
        </p>
        <p>
          the limitations of love have to do with perceived qualities and
          differences. By inner self-honesty and examination, these areas of
          limitation are revealed, usually, as residual judgments or as the
          impact from prior experience.
        </p>
      </div>
    </div>
  );
};
export default Love;

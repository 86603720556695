import { Link } from "react-router-dom";

const Willingness = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Willingness</h1>
        <p>
          at this level, growth is rapid and success is common in all endeavors.
        </p>
        <p>
          willingness implies that one has overcome inner resistance to life and
          is committed to participation.
        </p>
        <p>
          people become genuinely friendly, and social and economic successes
          seem to follow automatically. the willing are not troubled by
          unemployment; they will take any job when they have to, or create a
          career, or become self-employed. they do not feel demeaned by service
          jobs or by starting 'at the bottom'.
        </p>
        <p>
          self-esteem is high and reinforced by positive feedback from society
          in the forms of recognition, appreciation, and reward. willingness is
          sympathetic and responsive to the needs of others.
        </p>
        <p>
          spiritual gratification is an unsuspected source of pleasure that
          brings a greater sense of well-being, which is the consequence of an
          increase in the flow of spiritual energy.
        </p>
        <p>
          there is a greater sense of aliveness and appreciation for life as its
          quality progressively improves. the experience is subjective,
          nonlinear, and subtle but all pervasive. confidence and optimism
          replace doubt, mistrust, resistance, and cynicism. struggle is
          replaced with ease.
        </p>
        <p>
          with relinquishment of resistance, less effort is required to function
          in the world. the intrinsic rewards of spiritual growth become
          self-activating motivation that evolves into enthusiasm as a
          consequence of the more positive view of self and life.
        </p>
        <p>
          willingness has the extra energy that would otherwise be wasted on
          resistance, delay, and complaints. willingness energises fulfilling
          the needs of others, and thus its social expression is benevolent and
          humanitarian.
        </p>
        <p>
          it is the level of the golden rule: 'do unto others as you would have
          others do unto you'. in successful relationships this results in a
          mutuality og partners as helpmates and companions. this mutuality is
          the alignment with each other's welfare rather than just the more
          animal-driven emotional involvment.
        </p>
        <p>
          willingness is supportive rather than competitive for gain or
          dominance, and relationships involve service to each other's growth
          and goals rather than to just one's own.
        </p>
        <p>willingness is harmonious and is expressed as the 'win-win' attitude.</p>
      </div>
    </div>
  );
};
export default Willingness;

import { Text } from "@react-three/drei";
import { useFrame } from "@react-three/fiber";
import { useRef } from "react";

const Selfinder = () => {
  const text = useRef();

  // useFrame((state, delta) => {
  //   if text.current.position <= window.width {
  //   text.current.position.x += delta;}
  //   else {
  //     text.current.position =
  //   }
  // });

  return (
    <>
      <Text
        ref={text}
        position={[0, 3, 0]}
        color={"#CCC2E0"}
        fontSize={1.5}
        font="./fontsCSS/Panchang-Medium.woff"
      >
        SELFINDER
        <meshStandardMaterial />
      </Text>
    </>
  );
};

export default Selfinder;

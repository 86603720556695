import { Link } from "react-router-dom";

const Desire = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Desire</h1>
        <p>
          desire motivates vast areas of human activity, including the economy.
          advertisers play on desires to progrm us with needs linked to
          instinctual drives. it is linked to jealousy and greed.
        </p>
        <p>
          desire moves us to expend great effort to achieve goals or obtain
          rewards. the desire for money, prestige, or power runs the lives of
          many of those who have risen above fear as their predominant life
          motive.
        </p>
        <p>
          desire is also the level of addictions, wherein desire becomes a
          craving more important than life itself. the victims of deisre may
          actually be unaware of the basis of thier motives.
        </p>
        <p>
          the desire for sexual approval has produced the huge cosmetics and
          fashion industries that extol glamour and allure.
        </p>
        <p>
          desire has to do with acquisition and accumulation, but satisfaction
          of one desire is merely replaced by the unsatisfied desire for
          something else, so the acquisition is endless.
        </p>
        <p>
          the consequence of a life of endless pursuit and anixiety about the
          acquisition of the externalised, artificial sources of satisfaction is
          increased exposure to fear of loss.
        </p>
        <p>
          cravings could be continuous due to a failure of the internal
          satisfaction mechanism by which there never seems to be enough, and
          acquisition becomes a lifestyle of endless pursuit.
        </p>
        <p>
          social craving is often compensatory to self-doubt and low
          self-esteem. social expressions of needing and wanting may attach to
          external concepts, political positions, and the need to control others
          for the sought-for-feelings of importance and public attention.
        </p>
        <p>
          the basic problem with this level of consciousness is the inner
          feeling of lack that results in chronic dissatisfaction. the ego
          becomes infatuated with its own projections of attractiveness on the
          external objects, without reecongnising the value of what it already
          possesses.
        </p>
        <p>
          the ego's inner anixiety about fulfillment of its projected needs
          leads to an insatiable greed for power and control over others that
          emerges in its most expanded form as a desire to dominate the entire
          world.
        </p>
      </div>
    </div>
  );
};
export default Desire;

import { Link } from "react-router-dom";

const Reason = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Reason</h1>
        <p>
          intelligence and rationality rise to the forefront when the
          emotionalism of the lower levels is transcended.
        </p>
        <p>
          reason is capable of handling large, comples amounts of data and
          making rapid, correct decisions; of understanding the intricacies of
          relationships, gradations, and fine distinctions; and of expert
          manipulation of symbols as abstract concepts become increasingly
          important.
        </p>
        <p>
          understanding of information and logic are the main tools of
          accomplishment that are the hallmarks of level 400. this is the level
          of Nobel Prize winners, great statesmen, Supreme Court Justices,
          Einstein, Freud, and many other important figures in the history of
          thought.
        </p>
        <p>
          the shortcomings of this level are the failure to clearly distinguish
          the difference between symbols and what they represent, and the
          confusion between the objective and subjective worlds that limits the
          understanding of causality.
        </p>
        <p>
          intellectualising can become an end in itself. reason is limited in
          that it does not afford the capacity for the discernment of essence or
          the 'critical point' of a complex issue.
        </p>
        <p>
          reason is disciplined by the dialectic of logic as a necessity to
          discern the linear truth of confirmable facts. it produces massive
          amounts of information and documentation, but it lacks the capacity to
          resolve discrepancies in data and conclusions.
        </p>
        <p>
          although reason is highly effective in a technical world where the
          methodologies of logic dominate, reason itself, paradoxically, is the
          makor block to reaching higher levels of consciousness because it
          attracts identification of the self as mind.
        </p>
        <p>
          the mind is satisfied with the acquisition of knowledge but then
          discovers that alone it is insufficient to bring about transformation,
          which requires a further step to convert data into an inner
          experiential reality.
        </p>
        <p>
          the intellect is used to being satisfied by hearing 'about' a subject
          and may naively conclude that the information itself should be
          sufficient. while this is often partially true, at other times, the
          transfer from the acquisition of information to subjective experience
          comes about through spiritual practice, meditation, contemplation, and
          devotion.
        </p>
        <p>
          a major deterrent to transcending identification of the slf with mind
          is the processing of data, symbols, and words via random mentaliation.
          during meditation, this mental chatter is frustrating and becomes a
          source of anixiety. to try to silence the mind via will power is
          ineffective, and the results are limited and brief. by understadning
          the source of the flow of mentalisation, it can be transcended.
        </p>
        <p>
          mentalisation is of egocentric origin, and its primary function is
          commentary. unless requested, thought is a vanity, an endless
          procession of opinion, rationalisation, reprocessing, evaluating, and
          subtle judgment by which the thoughts are given valuue or importance
          via presumed significance because they are 'mine'.
        </p>
        <p>
          the 'importance' of thoughts is a self-appointed vanity. the ego
          presumes that it has the 'right' to entrude upon peace and silence
          with endless chatter. the mind has an imaginary audience and carries
          on a monologue for self-audition. the undisciplined mind has an
          observation commentaty or opinion on everything.
        </p>
        <p>
          it is a relief to let the mind become silent and just 'be' with
          surroundings. peace results. in order to realise that a running
          commentary is not necessary or even authorised, the will gives the
          mind permission to be silent.
        </p>
      </div>
    </div>
  );
};
export default Reason;

import React, { useRef, useState, useEffect } from "react";
import * as THREE from "three";
import { useFrame, useThree } from "@react-three/fiber";

import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/Addons.js";
import { DRACOLoader } from "three/examples/jsm/Addons.js";
import { Sparkles, Stars } from "@react-three/drei";
import Laser from "../components/Laser";

const Spaceship = ({ onPositionChange, onShoot }) => {
  const spaceshipRef = useRef();
  const laserRef = useRef();
  const { camera } = useThree();
  const [keys, setKeys] = useState({});

  useEffect(() => {
    const handleKeyDown = (e) =>
      setKeys((keys) => ({ ...keys, [e.key]: true }));
    const handleKeyUp = (e) => setKeys((keys) => ({ ...keys, [e.key]: false }));

    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, []);

  const spaceshipModel = useLoader(
    GLTFLoader,
    "./models/spaceship.gltf",
    (loader) => {
      const dracoLoader = new DRACOLoader();
      dracoLoader.setDecoderPath("./draco/");
      loader.setDRACOLoader(dracoLoader);
    }
  );
  const engineLightRef = useRef();

  useFrame(() => {
    if (keys[" "]) {
      const laserStartPosition = spaceshipRef.current.position
        .clone()
        .add(
          new THREE.Vector3(0, 0, 1.8).applyQuaternion(
            spaceshipRef.current.quaternion
          )
        );
      const laserDirection = new THREE.Vector3(0, 0, 1).applyQuaternion(
        spaceshipRef.current.quaternion
      );
      laserRef.current.shootLaser(laserStartPosition, laserDirection);
    }

    if (spaceshipRef.current) {
      const speed = 0.1;
      const forwardSpeed = 0.05;

      // movement using keys
      if (keys["ArrowUp"]) spaceshipRef.current.position.y += speed;
      if (keys["ArrowDown"]) spaceshipRef.current.position.y -= speed;
      if (keys["ArrowLeft"]) spaceshipRef.current.position.x += speed;
      if (keys["ArrowRight"]) spaceshipRef.current.position.x -= speed;

      // constant speed
      const forward = new THREE.Vector3(0, 0, 1);
      forward.applyQuaternion(spaceshipRef.current.quaternion);
      spaceshipRef.current.position.addScaledVector(forward, forwardSpeed);

      // camera going along with the spaceship
      camera.position
        .copy(spaceshipRef.current.position)
        .add(new THREE.Vector3(0, 5, -10));
      camera.lookAt(
        spaceshipRef.current.position.x,
        spaceshipRef.current.position.y + 2,
        spaceshipRef.current.position.z
      );

      if (onPositionChange) {
        onPositionChange(spaceshipRef.current.position);
      }

      if (engineLightRef.current) {
        const enginePosition = spaceshipRef.current.position
          .clone()
          .add(new THREE.Vector3(0, -1, -2));
        engineLightRef.current.position.copy(enginePosition);
      }
    }
  });

  return (
    <>
      <primitive
        ref={spaceshipRef}
        keys={keys}
        object={spaceshipModel.scene}
        scale={0.5}
        position={[0, -3, 0]}
      />
      <pointLight
        ref={engineLightRef}
        color="red"
        intensity={10}
        distance={5}
      />
      <Laser ref={laserRef} />

      <Sparkles
        count={500}
        speed={1}
        opacity={0.8}
        color="turquoise"
        size={2}
        scale={[50, 50, 50]}
        noise={[1, 1, 1]}
      />

      <Stars
        radius={100}
        depth={50}
        count={5000}
        factor={4}
        saturation={0.3}
        fade
        speed={1}
      />
    </>
  );
};

export default Spaceship;

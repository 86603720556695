import { Link } from "react-router-dom";
import FeelingLuckyList from "./list/FeelingLuckyList";
import { useState } from "react";

const TextSelfinder = () => {
  const [randomMessage, setRandomMessage] = useState("");

  const handleClick = () => {
    const randomIndex = Math.floor(Math.random() * FeelingLuckyList.length);
    const selectedMessage = FeelingLuckyList[randomIndex];
    setRandomMessage(selectedMessage.message);
  };
  return (
    <>
      <div className="buttonsTop">
        <button className="feelingLuckyButton" onClick={handleClick}>
          feeling lucky
        </button>
        {randomMessage && <div className="randomMessage">{randomMessage}</div>}

        <Link to="/levels" className="levelsButton">
          levels
        </Link>
        <Link to="/frequencyupgrade" className="upgradebutton">
          info
        </Link>
        <button>raise vibration</button>
      </div>
      <a
        href="https://www.amazon.co.uk/dp/B0D4FK5SPX?ref_=cm_sw_r_cp_ud_dp_WS3W9AXG65Z4MRVM4JZM"
        target="_blank"
        rel="noopener noreferrer"
        className="bookButton"
      >
        book
      </a>
      <Link to="/thingsfromspace" className="socials">
        spacegame
      </Link>
    </>
  );
};

export default TextSelfinder;

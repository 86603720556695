import React, { useState, useEffect, useRef } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const generateAsteroidPosition = (spaceshipPosition) => {
  const offsetX = (Math.random() - 0.5) * 50; // Random offset in x
  const offsetY = (Math.random() - 0.5) * 50; // Random offset in y
  const offsetZ = Math.random() * 100 + 50; // Random offset in z to be always in front

  return new THREE.Vector3(
    spaceshipPosition.x + offsetX,
    spaceshipPosition.y + offsetY,
    spaceshipPosition.z + offsetZ
  );
};

const Asteroids = ({ spaceshipPosition, lasers, setLasers }) => {
  const [asteroids, setAsteroids] = useState([]);
  const asteroidSpeed = 0.005;
  const asteroidRefs = useRef([]);

  useEffect(() => {
    const interval = setInterval(() => {
      const newPosition = generateAsteroidPosition(spaceshipPosition);

      setAsteroids((prevAsteroids) => [
        ...prevAsteroids,
        { position: newPosition, id: Math.random(), ref: React.createRef() },
      ]);
    }, 1000);

    return () => clearInterval(interval);
  }, [spaceshipPosition]);

  useFrame(() => {
    // Move asteroids towards the spaceship
    setAsteroids((prevAsteroids) =>
      prevAsteroids.map((asteroid, index) => {
        const ref = asteroidRefs.current[index];
        if (ref) {
          ref.position.lerp(spaceshipPosition, asteroidSpeed);
        }
        return asteroid;
      })
    );

    // Check for collisions
    setAsteroids((prevAsteroids) =>
      prevAsteroids.filter((asteroid, index) => {
        const ref = asteroidRefs.current[index];
        if (!ref) return true;

        const isHit = lasers.some((lasers) => {
          const distance = ref.position.distanceTo(lasers.position);
          return distance < 1; // Collision threshold
        });

        if (isHit) {
          return false; // Remove the asteroid
        }

        return true; // Keep the asteroid
      })
    );

    // Remove lasers that hit an asteroid
    if (setLasers) {
      setLasers((prevLasers) =>
        prevLasers.filter((laser) => {
          return !asteroids.some((asteroid) => {
            const distance = asteroid.position.distanceTo(laser.position);
            return distance < 1; // Collision threshold
          });
        })
      );
    } else {
      console.log("setLasers is undefined");
    }
  });

  return (
    <>
      {asteroids.map((asteroid, index) => (
        <mesh
          key={asteroid.id}
          ref={(el) => (asteroidRefs.current[index] = el)}
          position={asteroid.position}
        >
          <sphereGeometry args={[1, 8, 8]} />
          <meshBasicMaterial color="red" />
        </mesh>
      ))}
    </>
  );
};

export default Asteroids;

import { StrictMode } from "react";

import FrontPage from "./FrontPage";
import Socials from "./Socials";
import GameHolder from "./game/GameHolder";
import "./style.css";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LevelsFullPage from "./levels/LevelsFullPage";
import FrequencyUpgrade from "./upgrade/FrequencyUpgrade";
import Enlightenment from "./levels/Enlightenment";
import Peace from "./levels/Peace";
import UnconditionalLove from "./levels/UnconditionalLove";
import Love from "./levels/Love";
import Reason from "./levels/Reason";
import Acceptance from "./levels/Acceptance";
import Willingness from "./levels/Willingness";
import Neutrality from "./levels/Neutrality";
import Courage from "./levels/Courage";
import Pride from "./levels/Pride";
import Anger from "./levels/Anger";
import Desire from "./levels/Desire";
import Fear from "./levels/Fear";
import Grief from "./levels/Grief";
import Apathy from "./levels/Apathy";
import Guilt from "./levels/Guilt";
import Shame from "./levels/Shame";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(
  <StrictMode>
    <Router>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/thingsfromspace" element={<GameHolder />} />
        <Route path="/socials" element={<Socials />} />
        <Route path="/frequencyupgrade" element={<FrequencyUpgrade />} />
        <Route path="/levels" element={<LevelsFullPage />} />
        <Route path="/levels/frequencyupgrade" element={<FrequencyUpgrade />} />
        <Route path="/levels/enlightenment" element={<Enlightenment />} />
        <Route path="/levels/peace" element={<Peace />} />
        <Route
          path="/levels/unconditionallove"
          element={<UnconditionalLove />}
        />
        <Route path="/levels/love" element={<Love />} />
        <Route path="/levels/reason" element={<Reason />} />
        <Route path="/levels/acceptance" element={<Acceptance />} />
        <Route path="/levels/willingness" element={<Willingness />} />
        <Route path="/levels/neutrality" element={<Neutrality />} />
        <Route path="/levels/courage" element={<Courage />} />
        <Route path="/levels/pride" element={<Pride />} />
        <Route path="/levels/anger" element={<Anger />} />
        <Route path="/levels/desire" element={<Desire />} />
        <Route path="/levels/fear" element={<Fear />} />
        <Route path="/levels/grief" element={<Grief />} />
        <Route path="/levels/apathy" element={<Apathy />} />
        <Route path="/levels/guilt" element={<Guilt />} />
        <Route path="/levels/shame" element={<Shame />} />
      </Routes>
    </Router>
  </StrictMode>
);

import { Link } from "react-router-dom";

const Enlightenment = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Enlightenment</h1>
        <p>
          illumination, self-realisation, and enlightenment denote the Divine
          states that have historically demonstrated the highest levels of
          consciousness. these conditions represent the transcendence of the
          limitations of the constraints of the linearity of the ego and the
          emergence of the radiance of the infinite reality and source of
          existence.
        </p>
        <p>the</p>
      </div>
    </div>
  );
};
export default Enlightenment;

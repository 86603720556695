import { Link } from "react-router-dom";

const Acceptance = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Acceptance</h1>
        <p>
          at this level of awareness, a major transformation takes place with
          the understanding that oneself is the source and creator of the
          experience of one's life.
        </p>
        <p>
          the enormous jump of taking back one's own power (in comparison to the
          levels below 200) is completed at this level with the realisation that
          the source of one's own happiness is within oneself. love is not
          something that is given or taken away by another but is created from
          within.
        </p>
        <p>
          there is emotional calm, and perception is widened as denial is
          transcended. life is lived on life's terms without trying to make it
          conform to the angenda.
        </p>
        <p>
          the context of experience is expanded so that one is capable of
          'seeing the whole picture'.
        </p>
        <p>
          the individual at this level prioritises long-term goals and masters
          the self-discipline.
        </p>
        <p>
          social plurality begins to emerge as a form of resolution of problems,
          therefore, this level is free of extremes of discimination or
          intolerance. acceptance includes rather than rejects.
        </p>
        <p>
          acceptance is the result of wisdom as well as surrendering
          positionalities in that it accepts that the varied expressions of life
          are in accord with Divine will and that Creation is thereby
          multitudinous in its expressions as evolution.
        </p>
        <p>
          acceptance doesn't get caught in the 'black or white' duality and is
          able to bypass the temptation of judhmentalism.
        </p>
        <p>
          with freedom from the need of approval by others, there is release
          from the compulsion to seek and crave sociaal agreement.
        </p>
        <p>
          acceptance applies to the inner as well as the outer world. with time,
          it becomes apparent that the ego, by virtue of its innate structure,
          is prone to perceptual error, and that by the willingness to surrender
          a positionality (a particular perception of what there is), these
          distortions of perception are transcended.
        </p>
        <p>
          the maturity of acceptance includes the ability to tranquilly accept
          both personal and human limitations without loss of self-esteem
          because value judgments have lost their validity and are now seen to
          be primarily arbitrary, personalised choices.
        </p>
        <p>
          personal opinions become dethroned and lose their tendency to dominate
          by sheer emotional pressure.
        </p>
        <p>
          acceptence precludes pretense and allows for realistic objectivity.
        </p>
        <p>
          while it is obvious that there are many elements and forces in the
          world that are deleterious to human life and happiness, it is not
          necessary to hate or demonise them but instead to merely make
          appropriate allowances and avoid them. thus, what was formely
          demonised now appears to be more like bad weather, a tidal wave, or a
          force of nature to be reckoned with but not hated.
        </p>
        <p>
          surrendering judgmentalism results in freedom from pejorative and
          hateful emotions that in themselves bring up either conscious or
          unconscious guilt or unconscious fears of retaliation and paranoia.
        </p>
        <p>
          to decline the role of moral arbiter allows the surrendering of that
          function to God - 'Judgment is mine', sayeth the Lord. and results in
          detachment from the world's endless debates over moral, ethical,
          legal, political, religious, ethic, judicial, and social
          personalities.
        </p>
        <p>
          with humility, one's personal life loses false value and accepts its
          true power and function that increase spiritual energy and power,
          thereby influencing the world, especially through the collective
          consciousness of mankind.
        </p>
        <p>
          the narcissistic ego, at some of the lower levels, is humorless and
          reveals its true nature by its 'sensitivity' and other neurotic
          traits. it lacks the capacity to laugh at oneself and the foibles and
          paradoxes of human llife. thus, developing a sense of humour assists
          the evolution of consciousness through deflating the ego's puffed-up
          self-image by which it imbues its emotionalised opinionating and
          vanity.
        </p>
        <p>
          humility precludes making a fool or sectactle of oneself to gain
          attention or control others by bombastic shouting and gesticulation.
          acceptance declines drama and allows calm plurality without getting
          marginalised by the inflation of pumped-up positionalities that, by
          their very inflation, attract argument and attack.
        </p>
      </div>
    </div>
  );
};
export default Acceptance;

import { Canvas } from "@react-three/fiber";

import Experience from "./Experience";
import TextSelfinder from "./TextSelfinder";

const FrontPage = () => {
  return (
    <div className="noScroll">
      <TextSelfinder />
      <Canvas camera={{ fov: 50, near: 0.1, far: 200, position: [2, 2, 13] }}>
        <Experience />
      </Canvas>
    </div>
  );
};

export default FrontPage;

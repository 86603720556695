import { useLoader } from "@react-three/fiber";
import { GLTFLoader } from "three/examples/jsm/Addons.js";
import { DRACOLoader } from "three/examples/jsm/Addons.js";

const Ruby = () => {
  const ruby = useLoader(GLTFLoader, "./models/ruby.gltf", (loader) => {
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath("./draco/");
    loader.setDRACOLoader(dracoLoader);
  });

  return (
    <>
      <primitive object={ruby.scene} scale={0.5} position={[-2, 3, 0]} />
    </>
  );
};

export default Ruby;

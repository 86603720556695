import { Link } from "react-router-dom";

const Grief = () => {
  return (
    <div>
      <Link to="/levels" className="backButton">
        Back
      </Link>
      <div className="aLevelContainer">
        <h1>Grief</h1>
        <p>feels like: sadness, loss, despondency.</p>
        <p>manifests as: mourning, bereavement, remorse about the past.</p>
        <p>
          most people have experienced it for periods of time, but those who
          remain at this level live a life of constant regret and depression,
          which makes them feel like a loser and accept failure as part of their
          lifestyle, often resulting in the loss of jobs, friends, family, and
          opportunity, as well as money and health.
        </p>
        <p>
          in grief, one sees sadness everywhere, everything seems miserable and
          grim.
        </p>
        <p>
          though grief is the cemetry of life, it has more energy to it than
          does apathy. thus, when traumatised apathetic patients begin to cry,
          they are getting better, for once they start to cry, they will eat
          again.
        </p>
        <p>
          the universality of the experience is due to the structure and nature
          of the ego, which misperceives the source of happiness as external or
          emotional and imbues it with specialness. In reality, the only source
          of happiness is from within, and its mechanism is intrapsychic and
          internal.
        </p>
        <p>
          society collectively assumes that certain conditions, objects, or
          qualities are valuable, while in reality the value us always in the
          eyes of the beholder and never is intrinsic to the desired object or
          person itself.
        </p>
        <p>
          it is possible to evolve spiritually so as to become relatively immune
          to grief - to realise that the source of happiness originates from
          within and is not dependent on externals.
        </p>
        <p>
          if the source of happiness is acquired through ego mechanisms,
          objects, qualities or relatinships become overvalued by virtue of the
          mechanism of attachment.
        </p>
        <p>
          fear of loss contributed to dependent attachments as well as
          materiality or social attributes, such as money and fame.
        </p>
        <p>
          a loss at first is an unwelcome event because it is disruptive and
          emotionally intrusive. the initial response may be shock, resentment,
          disbelief. processing the crisis is helped by focusing on certain
          inner realities and transcending their the limitations.
        </p>
        <p>
          disruption of life by the unexpected also creates anixiety at the
          forced reqdjustment, which may require major decision making.
          suffering and emotional pain result largely to resistance to what is
          and what could be, so its cure is via surrender, acceptance and trust
          that everything that happens is for the highest good.
        </p>
        <p>
          the source of pain is not the belief system itself but one's
          attachment to it and the inflation of its imaginary value. the inner
          processing of attachments is dependent on the exercise of the will,
          which alone has the power to surrender.
        </p>
        <p>
          grief has to do with loss, and loss implies prior ownership and a
          special relationship. the idea of 'mine' or 'my' denotes a unique
          contextualisation by which a separate 'I' is magically bonded, in
          fantasy, to an 'it' or a 'you', and thereby to some quality,
          possession, or person. for ex, a watch is merely just an object, but
          with claim of ownership, it is now imbued with a unique, special
          quality called 'mine'.
        </p>
        <p>
          the emotional charge can be loosened by realising that everything
          actually belonged to the universe at large. ownership in human terms
          is a transitory specialnesss, and value and worth are only in
          perception, conceptualisation, and legalities.
        </p>
      </div>
    </div>
  );
};
export default Grief;

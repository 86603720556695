import React, { useRef, useState, useEffect } from "react";
import { useFrame } from "@react-three/fiber";
import * as THREE from "three";

const DynamicSky = ({ spaceshipPosition }) => {
  const [starPositions, setStarPositions] = useState([]);
  const visibleRange = 300;
  const starCount = 500;

  const generatePositions = (count, range) => {
    const positions = [];
    for (let i = 0; i < count; i++) {
      positions.push(
        new THREE.Vector3(
          spaceshipPosition.x + (Math.random() - 0.5) * range,
          spaceshipPosition.y + (Math.random() - 0.5) * range,
          spaceshipPosition.z + (Math.random() - 0.5) * range
        )
      );
    }
    return positions;
  };

  useEffect(() => {
    setStarPositions(generatePositions(starCount, visibleRange));
  }, []);

  useFrame(() => {
    setStarPositions((prevPositions) => {
      const newPositions = prevPositions.filter(
        (pos) => pos.distanceTo(spaceshipPosition) < visibleRange
      );

      while (newPositions.length < starCount) {
        newPositions.push(
          new THREE.Vector3(
            spaceshipPosition.x + (Math.random() - 0.5) * visibleRange,
            spaceshipPosition.y + (Math.random() - 0.5) * visibleRange,
            spaceshipPosition.z + (Math.random() - 0.5) * visibleRange
          )
        );
      }

      return newPositions;
    });
  });

  return (
    <>
      {starPositions.map((pos, index) => (
        <mesh key={index} position={pos}>
          <sphereGeometry args={[0.1, 8, 8]} />
          <meshBasicMaterial color="white" />
        </mesh>
      ))}
    </>
  );
};

export default DynamicSky;

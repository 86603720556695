import React, { useState, useEffect } from "react";

const Score = () => {
  const [score, setScore] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setScore((prevScore) => prevScore + 1);
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <div className="scoreDiv">Score: {score}</div>
    </>
  );
};

export default Score;
